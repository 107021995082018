.select-box {
  cursor: pointer;
  display: flex;
  margin: 0 24px;
  margin-top: 30px;
  justify-content: space-between;
  padding-bottom: 10px;
}
.select-box .box-img-choose {
  background-image: url(../../../../public/box-choose.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 57px;
  height: 57px;
  background-color: #0a6629;
  box-shadow: 0px 3.45455px 3.45455px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.select-box .select-box-title {
  font-size: 14px;
  line-height: 25px;
  color: #585858;
  max-width: 236px;
}
