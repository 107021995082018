.finder-container {
  padding: 0 20px;
}
.finder-description {
  margin: 20px 0;
}
.finder-description p {
  color: #585858;
  font-size: 17px;
  margin: 0;
  line-height: 25px;
}
.finder-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 35px 33px 10px 33px;
}
.finder-box .finder-footer {
  font-size: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
  color: #818181;
}
.finder-box .finder-footer p {
  margin: 0;
}
.finder-box .finder-group {
  position: relative;
}
.finder-box .finder-group input {
  width: 100%;
  border: 2px solid #585858;
  border-radius: 3px;
  font-family: 'Sora', sans-serif;
  height: 51px;
  padding: 12px 0 13px 13px;
  font-size: 18px;
  position: relative;
  z-index: 10;
  background: transparent;
}
.finder-box .finder-group input:focus {
  outline: none;
  border: 2px solid #0a6629;
}
.finder-box .finder-group input:focus + label {
  color: #0a6629;
}
.finder-box .finder-group input.border-error-found {
  border: 2px solid #d72020;
}
.finder-box .finder-group .label-finder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border: 2px solid #585858;
  border-radius: 3px;
  padding: 12px 0 13px 13px;
  font-size: 18px;
  z-index: 9;
  color: #a4a4a4;
  height: 51px;
}
.finder-box .finder-group label {
  position: absolute;
  left: 11px;
  top: -7px;
  background-color: #fff;
  padding: 0 7px;
  color: #585858;
  font-size: 12px;
  z-index: 11;
}
.finder-box .finder-group label.error-found {
  color: #d72020;
}
.finder-check-staple {
  background: url(../public/active-union.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
}
.finder-check-disabled {
  background: url(../public/union.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
}
.error-found {
  color: #d72020;
}
.finder-header {
  margin-bottom: 10px;
}
.finder-header .found-item {
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}
