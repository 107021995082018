.order-info .step-group-line {
  display: flex;
  pointer-events: none;
}
.order-info .step-group-line .order-info-label {
  font-weight: 600;
  font-size: 14px;
}
.order-info .step-group-line .order-delivery-list {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  color: #444;
}
.order-info .step-group-line .order-info-value {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  color: #444;
}
.order-info .step-group-line p {
  font-size: 14px;
  font-weight: 300;
  color: #444;
}
.order-info .step-group-header p {
  font-size: 14px;
  font-weight: 300;
  color: #444;
}
.order-info .order-info-components {
  padding: 0 0 0 40px;
  margin-top: 10px;
}
.order-info .order-info-components ul li {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: #444;
  margin: 0;
  margin-top: 5px;
}
.order-info .order-info-components ul li .step-img-component {
  margin-right: 10px;
}
.order-info .order-info-components ul li .step-sku-component {
  margin-right: 5px;
}
