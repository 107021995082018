.label-container {
  display: block;
  position: relative;
  font-family: 'Inter', sans-serif;
}
.label-container .box-data {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.label-container .local-extra-content {
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}
.label-container .label-header-box-count {
  font-weight: 700;
  margin-top: 24px;
}
.label-container-body {
  border: 1px solid #000;
  width: 396px;
  height: 594px;
  display: flex;
  flex-direction: column;
}
.label-container-header {
  border-bottom: 1px solid #000;
  padding: 17px 16px;
  display: flex;
}
.label-container-header .label-header-address {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 12px;
  line-height: 16px;
}
.label-container-header .label-header-address ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.label-container-header .label-header-box-count {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  margin-left: auto;
  display: block;
  width: 130px;
  font-weight: 700;
}
.label-container-header .label-logo-header {
  background-image: url(../../public/box-logo-local.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 64px;
}
.label-container .label-local-text {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid #000;
}
.label-container .label-local-text p {
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.95px;
  text-transform: uppercase;
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
}
.label-container .label-main-content {
  padding: 16px;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.label-container .label-main-content .label-main-content-col:first-child {
  width: 170px;
  margin-right: 20px;
}
.label-container .label-main-content .label-main-content-col:last-child {
  width: 120px;
}
.label-container .label-main-content .label-order-number {
  margin-top: 24px;
}
.label-container .label-main-content .ship-to-address p {
  margin: 0;
}
.label-container .label-main-content .leave-on-backdoor {
  margin-top: 38px;
  font-size: 12px;
}
.label-container .label-main-content .label-main-content-heading {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
}
.label-container .label-footer {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
}
.label-container .label-footer .label-logo-header-footer {
  background: url(https://www.dropbox.com/s/ejxvqhp4skiw9mf/box-logo-local-footer.svg?raw=1);
  background-repeat: no-repeat;
  background-position: center;
  width: 77px;
  height: 24px;
}
.label-container .label-footer .label-footer-col p {
  margin: 0;
  font-size: 7px;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: left;
}
.container-print-row {
  display: flex;
  flex-wrap: wrap;
  page-break-after: always;
}
