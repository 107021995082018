.feed-group {
  background-color: #fff;
}
.feed-group .feed-group-title {
  background-color: #0a6629;
  color: #fff;
  font-size: 29px;
  line-height: 16px;
  font-weight: 700;
  padding: 18px 35px;
}
.feed-group .feed-item {
  padding: 17px 33px;
  border-bottom: 1px solid #dbdbdb;
}
.feed-group .feed-item:last-child {
  border-bottom: none;
}
.feed-group .feed-item-header {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 10px;
  display: flex;
}
.feed-group .feed-item-name {
  font-size: 14px;
  font-weight: 300;
}
.box-item .scan-box {
  top: 0;
  width: 36px;
  height: 36px;
  background-size: 50%;
  margin-left: 10px;
}
.pack-feed-empty {
  background-image: url(../public/feed-empty-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.img-leaf {
  background-image: url(../public/leaf-img.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 187px;
  height: 163px;
  margin: 0 auto;
}
.pack-feed-empty-text {
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  line-height: 30px;
  max-width: 270px;
  margin: 0 auto;
  color: #585858;
}
