.pick-dry {
  padding-bottom: 40px;
  min-height: 100vh;
  background-color: #f5f6f7;
}
.pick-dry .box {
  margin-top: 0;
  padding-top: 20px;
}
.pick-dry .step-group-footer {
  display: block;
}
.pick-dry .loader {
  border-top: 0.3em solid #e4e4e4;
  border-bottom: 0.3em solid #e4e4e4;
  border-left: 0.3em solid #0a6629;
  border-right: 0.3em solid #e4e4e4;
  width: 3em;
  height: 3em;
  margin: 0 auto;
  display: none;
}
#scanbar {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
  text-shadow: transparent;
  caret-color: transparent;
}
.step-img-check {
  background-image: url(../public/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.pick-group-item {
  padding: 20px 0;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.pick-group-item:first-child {
  border-top: none;
}
.pick-group-item-done {
  background-color: #e1e3e5;
  box-shadow: none;
}
.step-sku-check {
  margin-right: 20px;
}
.scan-model input {
  display: block !important;
  position: absolute;
  top: 50%;
  z-index: 999;
}
.scan-model-control {
  width: 100%;
  height: 52px;
  background-color: #0a6629;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-end;
}
.close {
  background: none;
  border: none;
  background-image: url(../public/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 28px;
  height: 28px;
}
.scan {
  background-color: #0a6629;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 800;
  background-image: url(../public/scan-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.scan-model {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #f5f6f7;
}
.scan-model section section {
  height: calc(100vh - 52px);
  bottom: 0;
}
.continue-btn {
  cursor: pointer;
  width: 250px;
  max-width: 250px;
  text-align: center;
  background-color: #0a6629;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.continue-btn a {
  display: block;
  padding: 17px 0;
  width: 100%;
}
.model-notification {
  position: absolute;
  z-index: 1000;
  top: 0;
  padding: 15px;
  left: 0;
  background-color: #0a6629;
  width: 80%;
  color: #fff;
}
.toast-success-container {
  background-color: #0a6629 !important;
}
