.pick-dry {
  padding-bottom: 40px;
  min-height: 100vh;
  background-color: #f5f6f7;
}
.step-group-footer.active-staple {
  display: block;
}
.pick-fridge {
  padding-bottom: 40px;
}
.pick-fridge .box {
  margin-bottom: 100px;
  padding-top: 20px;
  margin-top: 0;
}
.pick-fridge .ptr__children,
.pick-fridge .ptr__pull-down {
  transition: unset;
}
.pick-fridge .pick-group-item {
  cursor: auto;
}
.pick-fridge .pick-group-item .re-print {
  cursor: pointer;
}
.step-img-check {
  background-image: url(../public/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.pick-group-item {
  cursor: pointer;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
}
.pick-group-item:first-child {
  border-top: none;
}
.pick-group-item.group-item-disabled {
  background-color: #e1e3e5;
  pointer-events: none;
}
.pick-group-item-done {
  cursor: default;
  background-color: #e1e3e5;
  box-shadow: none;
}
.scan-model input {
  display: block !important;
  position: absolute;
  top: 50%;
  z-index: 999;
}
.scan-model-control {
  width: 100%;
  height: 52px;
  background-color: #0a6629;
}
.scan {
  cursor: pointer;
  background-color: #0a6629;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 800;
  background-image: url(../public/scan-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.scan-model {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
}
.scan-model section section {
  height: calc(100vh - 52px);
  bottom: 0;
}
.pick-fridge .continue-btn {
  cursor: pointer;
  width: 250px;
  max-width: 250px;
  text-align: center;
  background-color: #0a6629;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.pick-fridge .continue-btn a {
  padding: 17px 0;
  display: block;
  width: 100%;
}
.disable-scroll {
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
.re-print {
  background-color: #0a6629;
  /* align-self: flex-end; */
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-img-print {
  background-image: url(../public/print.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}
.one-print-meal {
  width: 259px;
  height: 369px;
}
.one-print-meal .container-rotate-page {
  width: 369px;
  height: 259px;
  transform: translate3d(0, 0, 0) rotate(-90deg) translate(0px, 369px);
  transform-origin: 0px 369px;
}
