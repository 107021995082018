.settings {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.settings .close {
  cursor: pointer;
  background: none;
  border: none;
  background-image: url(../public/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 21px;
  height: 21px;
}
.settings-footer {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
.settings-footer p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}
.settings-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 28px 0;
  font-weight: 600;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
}
.settings-item:last-child {
  border-bottom: none;
}
.settings-item-username {
  color: #444444;
}
.settings-item-email {
  font-size: 14px;
  color: #9e9e9e;
}
.toggle-item {
  margin-left: auto;
}
.icon-barcode {
  background-image: url(../public/icon-barcode.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
  margin-right: 20px;
}
.icon-user {
  background-image: url(../public/user.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 25px;
  margin-right: 20px;
}
.icon-qrcode {
  background-image: url(../public/icon-qrcode.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
  margin-right: 20px;
}
.icon-meal-page {
  background-image: url(../public/page-meal-black.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: auto;
  margin-right: 20px;
}
.icon-logout {
  background-image: url(../public/icon-logout.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
  margin-right: 20px;
}
.icon-printer {
  background-image: url(../public/icon-printer.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
  margin-right: 20px;
}
.logout-menu {
  background: none;
  border: none;
  font-size: 22px;
  padding: 0;
  font-family: 'Sora', sans-serif;
  font-weight: 600;
}
.settings-container {
  padding: 0 30px;
  flex: 1;
}
.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.react-toggle-track {
  width: 32px;
  height: 14px;
  padding: 0;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(0, 0, 0, 0.3);
}
.react-toggle--checked .react-toggle-track {
  background-color: rgba(52, 163, 79, 0.54);
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(52, 163, 79, 0.54);
}
.react-toggle--checked .react-toggle-thumb {
  background-color: #0a6629;
}
.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}
.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: -3px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fafafa;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.react-toggle--checked .react-toggle-thumb {
  left: 12px;
  border-color: rgba(52, 163, 79, 0.5);
}
