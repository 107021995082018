.list-box-size {
  margin-top: 24px;
}
.list-box-size li {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  transition: all 0.2s;
}
.list-box-size li:hover {
  background-color: #f7f7f7;
  padding-left: 10px;
}
.list-box-size li .box-img-boxing {
  background-image: url(../../../../public/box-boxing.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 46px;
  height: 46px;
  background-color: #0a6629;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.list-box-size li p {
  color: #696969;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}
.list-box-size li:last-child {
  margin-bottom: 0;
}
.close-choose-box {
  cursor: pointer;
  color: #0a6629;
  text-transform: uppercase;
}
.back-choose-box {
  cursor: pointer;
  color: #0a6629;
  text-transform: uppercase;
  margin-right: 10px;
}
