.wrapper-modal .modal-add-product {
  width: 100%;
  margin: 0 20px;
  overflow-y: scroll;
  height: calc(100vh - 40px);
  padding: 15px;
  padding-bottom: 100px;
}
.wrapper-modal .modal-add-product .step-group-footer {
  padding: 10px 0;
}
.wrapper-modal .modal-add-product .modal-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 16px;
}
.wrapper-modal .modal-add-product .modal-header .close-choose-box {
  margin-right: 20px;
}
.wrapper-modal .modal-add-product .modal-header .close-choose-box:last-child {
  margin-right: 0;
}
.wrapper-modal .modal-add-product .modal-add-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 17%;
}
.wrapper-modal .modal-add-product .selected-product.pick-group-item:first-child {
  border-top: 2px solid green;
}
.wrapper-modal .modal-add-product .pick-group-info {
  pointer-events: none;
}
.wrapper-modal .modal-add-product .pick-group-item {
  border: 2px solid transparent;
}
.wrapper-modal .modal-add-product .selected-product {
  border: 2px solid green;
}
.wrapper-modal .modal-add-product .disable-button {
  pointer-events: none;
  color: #aaa;
}
