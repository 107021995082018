@media (min-width: 425px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 414px) {
  .order-list .order-card .order-card-header .order-card-number {
    font-size: 25px;
  }
  .order-list .order-card .order-card-footer .order-footer-right {
    font-size: 15px;
  }
  .order-list .order-card .order-card-footer .order-card-state ul li {
    margin-right: 13px;
  }
  .header-col-center .header-label {
    font-size: 16px;
  }
}
