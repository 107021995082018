.order {
  min-height: 100vh;
  background-color: #f5f6f7;
}
.order-main {
  padding: 20px 0;
}
.order-main-header .order-username {
  font-size: 30px;
  font-weight: 700;
  color: #4d4d4d;
}
.order-main-header p {
  color: #808080;
}
.order-list {
  display: flex;
  flex-direction: column;
}
.order-list .continue-btn {
  max-width: 100%;
  width: 80%;
  position: static;
}
.order-card {
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  margin-top: 15px;
}
.order-card:first-child {
  margin-top: 0;
}
.order-card .order-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.order-card .order-card-header .order-card-number {
  font-size: 32px;
  font-weight: 700;
}
.order-card .order-card-header .order-card-label {
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
}
.order-card .order-card-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.order-card .order-card-footer .order-footer-right {
  font-size: 18px;
  text-align: right;
}
.order-card .order-card-footer .order-card-state ul {
  display: flex;
  min-width: 142px;
}
.order-card .order-card-footer .order-card-state ul li {
  display: flex;
  margin-right: 20px;
}
.order-card .order-card-footer .order-card-state ul li:last-child {
  margin-right: 0;
}
.order-card .order-card-footer .order-card-state ul li img {
  height: 20px;
}
.order-card .order-card-footer .order-card-state ul li span {
  margin-left: 5px;
  font-size: 19px;
}
.order-card.order-card-done {
  background-color: #e1e3e5;
  box-shadow: none;
}
.order-card.order-card-done .order-card-header {
  justify-content: flex-start;
}
.step-img-check {
  background-image: url(../public/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin-right: 10px;
  background-size: 60%;
}
.shipped-orders {
  overflow: hidden;
}
.shipped-orders .step-group-footer {
  display: block;
}
.shipped-orders .order-card-number {
  font-size: 32px;
  font-weight: 700;
}
.shipped-orders .pick-group-item {
  padding: 0;
  cursor: pointer;
  padding-top: 20px;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
}
.shipped-orders .pick-group-item .step-group-header,
.shipped-orders .pick-group-item .step-group-footer,
.shipped-orders .pick-group-item .step-group-info {
  margin: 0 20px;
}
.shipped-orders .pick-group-open .step-group-info {
  margin-top: 5px;
}
.shipped-error {
  background-image: url(../public/error-shipped.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 28px;
  margin-right: 20px;
}
