.header {
  background-color: #054929;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header .header-logo {
  margin-top: 30px;
}
.header-col {
  display: flex;
}
.header-col-center {
  width: 100%;
  display: flex;
  margin-left: 13px;
  align-items: center;
}
.header-col-center .header-label {
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}
.header-col-right {
  justify-content: flex-end;
  padding-left: 14px;
  border-left: 1px solid #07572a;
  display: flex;
  align-items: center;
}
.header-col-left {
  padding-right: 23px;
  border-right: 1px solid #07572a;
  display: flex;
  align-items: center;
  width: 38px;
  min-width: 38px;
}
.header-row {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 7px 30px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.logout {
  cursor: pointer;
  background: none;
  border: none;
  background-image: url(../../public/burger.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 25px;
  height: 25px;
}
.back {
  background: none;
  border: none;
  background-image: url(../../public/back.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 24px;
}

.home {
  background: none;
  border: none;
  background-image: url(../../public/home.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  margin-right: 25px;
  margin-top: 2px;
}