.step-list {
  padding-bottom: 40px;
  min-height: 100vh;
  background-color: #f5f6f7;
}
.step-list .step-group-footer {
  display: block;
}
.step-img-component {
  background-image: url(../public/box-component.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 20px;
}
.step-img-vitamin {
  background-image: url(../public/icon-vitamin.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 20px;
}
.box {
  padding: 0 20px;
  margin-top: 24px;
  margin-bottom: 70px;
}
.step-group-item {
  border-top: 1px solid rgba(136, 136, 136, 0.3);
  padding: 20px 0;
}
.step-group-item:first-child {
  border-top: none;
  padding-top: 0;
}
.step-group-container {
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.box-title {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 30px 40px;
}
.step-group-header {
  display: flex;
  padding-right: 50px;
  align-items: center;
  pointer-events: none;
}
.step-group-right {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}
.step-group-footer {
  pointer-events: none;
  padding: 0 40px;
  transition: all 0.5s;
}
.step-group-footer p {
  font-size: 14px;
  font-weight: 300;
  color: #444;
  margin: 0;
  margin-top: 5px;
}
.print-text-btn {
  display: none;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.pick-group-item.meal-toggle {
  display: block;
  transition: all 0.5s;
}
.pick-group-item.meal-toggle .step-group-footer {
  display: block;
  width: 80%;
}
.pick-group-item.meal-toggle .re-print {
  width: 110px;
  flex-direction: column;
}
.pick-group-item.meal-toggle .re-print .step-img-print {
  width: 42px;
  height: 42px;
  background-size: 42px;
}
.pick-group-item.meal-toggle .re-print .print-text-btn {
  display: block;
}
.proceed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0a6629;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  height: 74px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
}
.proceed-slip {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0a6629;
  color: #fff;
  border-radius: 10px;
  font-size: 17px;
  padding: 12px 0;
  cursor: pointer;
  position: fixed;
  bottom: 84px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
}
.proceed-slip-text {
  margin-right: 10px;
}
.proceed-label {
  font-weight: 600px;
  font-size: 22px;
}
.proceed-link {
  width: 100%;
  height: 100%;
  background-image: url(../public/printer-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.component-to-print {
  display: block;
  position: absolute;
  top: 0;
  left: -999999px;
  overflow: hidden;
}
.page-break {
  page-break-after: always;
  width: 600px;
  height: 804px;
  padding: 43px 26px 31px 31px;
}
.page-break-after {
  page-break-after: always;
}
.page-break:last-child {
  page-break-after: avoid;
}
.wrapper-container-print {
  position: relative;
}
.item-meal {
  overflow: hidden;
}
.print-logo-boxing {
  width: 300px;
  margin-top: 40px;
}
.print-label {
  font-weight: 500;
  margin: 20px 0;
}
.order-list .step-group-header {
  padding-right: 0;
}
.order-list .step-group-right {
  padding-left: 20px;
}
.order-all-to-print {
  display: flex;
}
.print-container-wrapper {
  position: relative;
}
.container-rotate-page {
  width: 742px;
  display: flex;
  justify-content: flex-end;
  transform: translate3d(0, 0, 0) rotate(-90deg) translate(0px, 742px);
  transform-origin: 0px 742px;
  page-break-after: always;
  overflow: hidden;
  height: 612px;
}
.container-rotate-page .row-print {
  margin-left: 2pt;
  margin-top: 3pt;
}
.row-print:last-child {
  margin-left: -7pt;
}
.meal-print-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 369px;
  height: 259px;
  margin-bottom: 17px;
  margin-left: 10px;
  margin-left: auto;
  margin-right: 1px;
}
.meal-print-container:last-child {
  margin-bottom: 0;
}
.meal-print-container .meal-print-header {
  display: flex;
  padding: 10px 18px 0;
  justify-content: space-between;
  position: relative;
}
.meal-print-container .meal-print-header .meal-print-tagline {
  font-size: 7px;
  font-weight: 500;
  width: 117px;
  line-height: 10px;
  z-index: 1;
  color: #fff;
}
.meal-print-container .meal-print-header .meal-print-number {
  margin-top: 11px;
  line-height: 11px;
  font-size: 9px;
  text-align: right;
}
.meal-print-container .meal-print-header .meal-print-number p {
  margin: 0;
  color: #000;
}
.meal-print-container .meal-print-header .meal-print-number .meal-print-number-heading {
  color: #87898c;
}
.meal-print-container .meal-print-middle {
  padding: 0 18px;
}
.meal-print-container .meal-print-middle .top-keep-fridge {
  display: flex;
  color: #0482cc;
  font-size: 6px;
  font-weight: 600;
  margin-bottom: 8px;
  align-items: center;
}
.meal-print-container .meal-print-middle .print-meal-name {
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #0a6629;
  font-size: 14px;
  width: 200px;
  line-height: 16px;
  letter-spacing: 1px;
}
.meal-print-container .meal-print-middle .print-meal-name p {
  margin: 0;
}
.meal-print-container .meal-print-middle .meal-print-instruction {
  margin-top: 7px;
}
.meal-print-container
  .meal-print-middle
  .meal-print-instruction
  .print-instruction-heading {
  color: #252728;
  font-size: 8px;
  font-weight: 600;
  margin-bottom: 7px;
}
.meal-print-container .meal-print-middle .meal-print-instruction ul {
  width: 230px;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #87898c;
  font-size: 7px;
  line-height: 8px;
  counter-reset: count;
}
.meal-print-container .meal-print-middle .meal-print-instruction ul li {
  display: flex;
  align-items: center;
}
.meal-print-container .meal-print-middle .meal-print-instruction ul li:before {
  counter-increment: count;
  content: counter(count);
  border-radius: 50%;
  border: 1px solid #87898c;
  font-size: 6px;
  font-weight: 800;
  line-height: 10px;
  min-width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.meal-print-container .meal-print-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  font-size: 7px;
  line-height: 7px;
  align-items: flex-end;
  margin-top: auto;
  position: relative;
}
.meal-print-container .meal-print-footer .meal-print-footer-col p {
  color: #87898c;
  margin: 0;
  width: 176px;
  line-height: 7px;
}
.meal-print-container .meal-print-footer .meal-print-footer-col .packing-slip {
  color: #0a6629;
  margin-top: 6px;
}
.meal-print-container .meal-print-footer .meal-print-footer-left {
  padding-bottom: 6px;
}
.meal-print-container .meal-print-footer .meal-print-footer-right {
  display: flex;
  z-index: 1;
  align-items: flex-end;
  padding-bottom: 10px;
}
.meal-print-container .meal-print-footer .meal-print-footer-right .meal-print-contacts {
  margin-right: 13px;
  color: #fff;
}
.meal-print-container
  .meal-print-footer
  .meal-print-footer-right
  .meal-print-contacts
  .meal-print-phone {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.meal-print-container
  .meal-print-footer
  .meal-print-footer-right
  .meal-print-contacts
  .meal-print-site {
  display: flex;
  align-items: center;
}
.meal-print-container .meal-print-footer .meal-print-footer-right .print-meal-scan-me {
  font-size: 4px;
  font-weight: 600;
  line-height: 6px;
  letter-spacing: 0.1px;
  text-align: center;
  text-transform: none;
  color: #fff;
}
.leaf-meal-print {
  width: 187px;
  height: 85px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url(../public/leaf-meal-print.svg);
  background-repeat: no-repeat;
  background-position: right;
}
.leaf-meal-print-top {
  width: 246px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../public/leaf-meal-print-top.svg);
  background-repeat: no-repeat;
  background-position: left;
}
.meal-print-logo {
  background-image: url(../public/meal-print-logo-header.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 20px;
}
.meal-print-snowball {
  background-image: url(../public/snowball.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 7px;
  height: 7px;
  margin-right: 3px;
}
.meal-print-phone-icon {
  background-image: url(../public/print-phone.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 6px;
  height: 6px;
  margin-right: 4px;
  background-size: 100%;
}
.meal-print-site-icon {
  background-image: url(../public/print-site.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 6px;
  height: 6px;
  margin-right: 4px;
}
.print-all-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0a6629;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  margin-left: 5px;
}
.actions-print-labels {
  display: flex;
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.actions-print-labels-row {
  display: flex;
  position: fixed;
  bottom: 84px;
  left: 0;
  right: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.actions-print-labels-row .proceed-slip {
  max-width: 100%;
  width: 100%;
  position: static;
  margin: 0;
}
.actions-print-labels .proceed-meals {
  min-width: 20%;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0a6629;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}

.actions-print-labels .proceed-meals-disabled {
  background-color: #e1e3e5;
}

.order-page .box {
  margin-top: 0;
}
.order-page .lists-items {
  padding: 20px 0;
}
#renderMaelWithoutBack {
  display: block;
  position: absolute;
  top: 0;
  left: -999999px;
}
.proceed-meals-img {
  width: 100%;
  height: 100%;
  background-image: url(../public/page-meal.svg);
  background-repeat: no-repeat;
  background-position: center;
}
