.box-view {
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px;
}
.box-view-header {
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
  display: flex;
  align-items: center;
  padding: 17px 0;
}
.box-view-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
}
.box-view-steps {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.113333px;
  margin-left: auto;
  color: #757575;
  min-width: 70px;
}
.max-value-weight {
  color: red;
}
.box-form.box-form-print .box-input input {
  border-bottom: none;
}
.box-form.box-form-print .box-form-row {
  margin-bottom: 20px;
}
.react-select-print {
  background-color: #d8d8d8;
  padding: 5px 10px 10px 10px;
}
.box-form-print-container {
  margin: 0 auto;
  width: 330px;
  padding: 50px 0;
}
.box-form-print-container .print-logo {
  width: 300px;
}
.box-form-print-container .box-input-lg {
  display: none;
}
.box-form-print-container input {
  border-radius: 0;
}
.box-form-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.box-form .box-input {
  width: 50%;
  margin-right: 22px;
}
.box-form .box-input:last-child {
  margin-right: 0;
}
.box-form .box-input label {
  display: flex;
  border-radius: 4px 4px 0px 0px;
  background-color: #d8d8d8;
  width: 100%;
  padding: 5px 10px 0 10px;
  color: #565656;
}
.box-form .box-input input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  height: 24px;
  width: 100%;
  background-color: #d8d8d8;
  font-size: 14px;
  padding: 0 10px;
  border-bottom: 2px solid #565656;
  border-radius: 0;
}
.box-form .box-input input:focus {
  border-bottom: 2px solid #5fd16f;
}
.box-form .box-input input.active-box-input {
  border-bottom: 2px solid #0a6629;
  border-radius: 0;
}
.box-form .box-input .box-input-desc {
  width: 100%;
  font-size: 10px;
  line-height: 16px;
  color: #818181;
}
.box-form .box-input.box-input-lg {
  width: 100%;
}
.box-form .box-input-notactive {
  display: none;
  pointer-events: none;
  opacity: 0.2;
}
.box-form .box-input-lg input {
  width: 100%;
}
.box-form .box-input-lg .active-box-input .react-select__control {
  border-bottom: 2px solid #0a6629;
}
.react-select__menu-list {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  border-radius: 4px;
}
.box-input-lg .react-select__control {
  border: none;
  background-color: #d8d8d8;
  border-radius: 0;
}
.box-input-lg .react-select__control--is-focused {
  box-shadow: none;
  border-bottom: 2px solid #5fd16f;
}
.box-input-lg .react-select__control:hover {
  border: none;
}
.box-input-lg .react-select__menu {
  margin-top: 0;
  z-index: 999;
}
.box-input-lg .react-select__option {
  background-color: #fff;
}
.box-input-lg .react-select__option:active {
  background-color: #0a6629;
}
.box-input-lg .react-select__option--is-selected {
  background-color: #054929;
}
.box-input-lg .react-select__indicator {
  color: #054929;
}
.box-input-lg .react-select__indicator-separator {
  display: none;
}
.rsw_3G {
  height: calc(100vh - 52px);
}
.img-subtract {
  background-image: url(/subtract.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin-left: 20px;
  margin-right: 5px;
}
.wrapper-print-box {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.print-box {
  width: 330px;
}
