.modal-continue .modal-footer {
  margin-top: 40px;
}
.modal-continue-btn {
  color: #0a6629;
  text-transform: uppercase;
  margin-left: 30px;
}
.modal-continue-btn:first-child {
  margin-left: 0;
}
