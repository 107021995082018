.select-tabs-screen.feed-screen {
  padding: 0;
  padding-bottom: 130px;
}
.feed-screen .select-box {
  margin: 0 44px;
  margin-top: 30px;
  border-bottom: 0;
}
.pack .ptr__pull-down {
  margin-top: 0;
}
.wrapper-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}
.wrapper-modal .modal {
  width: 312px;
  background: #fff;
  border-radius: 3px;
  padding: 30px;
}
.wrapper-modal .modal .modal-header {
  font-weight: 500;
  font-size: 22.624px;
}
.wrapper-modal .modal .modal-footer {
  display: flex;
  justify-content: flex-end;
}
.wrapper-modal .modal .modal-footer .modal-btn {
  font-size: 17px;
  text-transform: uppercase;
  color: #0a6629;
}
.wrapper-modal.wrapper-modal-scrollable {
  overflow: auto;
}
.scrollable-modal-content {
  position: absolute;
  top: 20px;
  padding-bottom: 20px;
}
