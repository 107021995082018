.modal-main {
  font-size: 20px;
  color: #696969;
  font-weight: 300;
}
.modal-main p {
  padding: 10px 0;
}
.wrapper-modal .modal-apply .close-choose-box {
  margin-left: 32px;
}
