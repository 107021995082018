.pack .continue-btn {
  max-width: none;
  width: auto;
  margin: 0 20px;
}
.pack .step-group-header {
  padding-right: 0;
}
.pack .step-group-header .step-group-right {
  padding-left: 20px;
}
