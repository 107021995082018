.scan {
  background-color: #0a6629;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  position: fixed;
  bottom: 60px;
  right: 40px;
  background-image: url(../../public/scan-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.scanShiftedUp {
  bottom: 100px;
}
