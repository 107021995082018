.btn-continue.active {
  cursor: pointer;
  width: 250px;
  max-width: 250px;
  text-align: center;
  background-color: #0a6629;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 0;
  pointer-events: auto;
}
.btn-continue {
  width: 250px;
  max-width: 250px;
  text-align: center;
  background-color: #e1e3e5;
  border-radius: 10px;
  color: #878787;
  font-size: 20px;
  position: fixed;
  bottom: 40px;
  pointer-events: none;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 0;
  z-index: 3;
}
