.wrapper-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}
.wrapper-modal .modal {
  width: 312px;
  background: #fff;
  border-radius: 3px;
  padding: 30px;
}
.wrapper-modal .modal .modal-header {
  font-weight: 500;
  font-size: 22.624px;
}
.wrapper-modal .modal .modal-main p {
  color: #696969;
  font-size: 19px;
  line-height: 27px;
  font-weight: 300;
  margin: 10px 0;
}
.wrapper-modal .modal .modal-footer {
  display: flex;
  justify-content: flex-end;
}
.wrapper-modal .modal .modal-footer .modal-btn {
  font-size: 17px;
  text-transform: uppercase;
  color: #0a6629;
}
