.pack-boxing-finished {
  background-image: url(../public/feed-empty-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0 20px;
}
.pack-boxing-finished .box-form {
  margin-top: 20px;
}
.img-leaf {
  background-image: url(../public/leaf-img.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 187px;
  height: 163px;
  margin: 0 auto;
}
.pack-boxing-finished-text {
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  line-height: 30px;
  max-width: 270px;
  margin: 0 auto;
  color: #585858;
}
.finished .header {
  position: relative;
  z-index: 1;
}
