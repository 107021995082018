.welcome-label {
  font-size: 23px;
}
.login {
  padding: 0 72px;
  background-image: url(../public/background.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  min-height: 100vh;
  background-color: #fff;
}
.hide-pass {
  background-image: url(../public/show-pass.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
}
.show-pass {
  background-image: url(../public/hide-pass.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-size: 30px;
}
.form-input {
  position: relative;
}
.input-control {
  position: absolute;
  right: 0;
  bottom: 6px;
}
.header-signin {
  display: flex;
  flex-direction: column;
  padding-top: 23px;
}
.header-signin img {
  width: 217px;
  align-self: center;
}
.header-signin .welcome-label {
  font-weight: 500;
  margin-top: 83px;
}
.header-signin .welcome-label .welcome {
  font-weight: 700;
  font-size: 34px;
}
.header-signin .welcome-label p {
  margin: 0;
  margin-top: 15px;
}
.login-form input {
  border: none;
  width: 100%;
  border-bottom: 1px solid #747474;
  border-radius: 0;
  font-size: 20px;
  padding: 9px 0;
  background: transparent;
}
.login-form input:focus {
  outline: none;
}
.login-form #password {
  margin-top: 52px;
}
.footer-signin {
  display: flex;
  font-size: 34px;
  align-items: center;
  margin-top: 20px;
  font-weight: 500;
}
.footer-signin .submit-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border: none;
  background-color: #e1e3e5;
  border-radius: 50%;
  margin-left: 20px;
  background-image: url(../public/arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  fill: blue;
  transition: 0.5s all;
}
.footer-signin .submit-login img {
  width: 60%;
}
button:focus {
  outline: 0;
}
.footer-signin .submit-color {
  background-color: #033b26;
  transition: 0.5s all;
  background-image: url(../public/active-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.container-message-error {
  height: 48px;
  margin: 24px 0;
  align-items: center;
  display: flex;
  justify-content: center;
}
.container-message-error .message-error-text {
  color: #ff3939;
  text-align: center;
}
.input-error-color {
  color: #ff3939;
}
@media (max-width: 411px) {
  .header-signin .welcome-label {
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .login {
    padding: 0 45px;
  }
}
@media (max-width: 320px) {
  .login-form #password {
    margin-top: 30px;
  }
  .container-message-error {
    margin: 13px 0;
  }
}
