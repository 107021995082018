.weighing {
  padding: 0 20px;
}
.weighing-header {
  display: none;
  flex-direction: row;
  padding: 15px 0 26px 0;
}
.weighing-header-description {
  font-size: 14px;
  line-height: 25px;
  /* or 179% */
  display: flex;
  align-items: center;
  /* Instructions */
  color: #585858;
}
.btn-continue.active.w-finished {
  padding: 0;
}
.btn-continue.active.w-finished a {
  display: block;
  width: 100%;
  padding: 20px 0;
}
.weighing-print {
  background-image: url(../public/weighing-print.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 58px;
  height: 58px;
  margin-left: 20px;
  border-radius: 50%;
  background-color: #e1e3e5;
  box-shadow: 0px 1.68547px 1.68547px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.weighing-print-active {
  cursor: pointer;
  background-image: url(../public/weighing-print-active.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 58px;
  height: 58px;
  margin-left: 20px;
  border-radius: 50%;
  background-color: #0a6629;
  box-shadow: 0px 1.68547px 1.68547px rgba(0, 0, 0, 0.25);
}
