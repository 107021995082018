.box-list-empty {
  padding: 20px 0;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.box-change {
  display: none;
}
.empty-box {
  color: #585858;
  display: flex;
  font-size: 17px;
  justify-content: center;
  padding-top: 20px;
}
.box-item-edit {
  cursor: pointer;
  background-image: url(../public/pen-edit.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 18px;
  height: 18px;
}
.rm-product {
  cursor: pointer;
  background-image: url(../public/rm-product.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 20px;
  height: 20px;
  margin-left: auto;
}
.remove-box {
  cursor: pointer;
  background-image: url(../public/close-dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  min-width: 18px;
  height: 18px;
}
.boxes {
  margin-bottom: 170px;
}
.active-box {
  color: #0a6629;
}
.box-list-container {
  padding-bottom: 130px;
}
.box-change {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 0 20px 30px 20px;
  margin-top: 50px;
}
.box-change-header {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
}
.box-change .box-change-list li {
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3);
  padding: 20px 0;
}
.box-change .box-change-list li:first-child {
  padding-top: 0;
}
.box-change .box-change-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.disabled-box {
  pointer-events: none;
  opacity: 0.5;
  transition: all 0.3s;
}
.box-container {
  transition: all 0.2s;
}
.box-item {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 30px 20px;
  margin-top: 20px;
  border: 2px solid #fff;
}
.box-item-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  position: relative;
}
.box-item-edit {
  margin-left: 7px;
}
.box-item-title {
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.113333px;
  color: #2b2b2b;
}
.scan-box {
  background-color: #0a6629;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  top: -55px;
  right: 0;
  z-index: 800;
  background-image: url(../public/scan-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  align-self: flex-end;
  margin-left: auto;
}
.box-item-product {
  padding: 14px 0;
}
.box-item-product-header {
  display: flex;
  align-items: center;
}
.add {
  cursor: pointer;
  background: none;
  border: none;
  background-image: url(../public/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  width: 36px;
  height: 36px;
  transform: rotate(45deg);
  background-color: #0a6629;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-left: 10px;
}
.btn-scan-device {
  cursor: pointer;
  background-color: #2974f6;
  background-image: url(../public/device-scanner.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.target-box {
  border: 2px solid green;
}
.target-box .btn-scan-device {
  animation: blink normal 1.5s infinite ease-in-out;
  margin-left: 10px;
}
@keyframes blink {
  0% {
    background-color: #2974f6;
  }
  50% {
    background-color: #0951cc;
  }
  100% {
    background-color: #2974f6;
  }
}
.bts-left-auto {
  display: flex;
  margin-left: auto;
}
.ptr.ptr--pull-down-treshold-breached .box-container:first-child .box-item {
  margin-top: 0;
}
